<template>
    <div class="text-center mt-5" v-if="hasProbValidTranslation('branding.logo')">
        <img :src="$t('branding.logo')" style="max-width: 150px;"/>
    </div>
</template>

<script>
export default {
    name: 'TenantBrandingLogo'
};
</script>
