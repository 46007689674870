<template>
    <div class="tenant-box-footer">
        <!-- I18N LINKS -->
        <div class="text-center mb-0" v-if="displayI18nLinks">
            <span class="text-primary small cursor-pointer mr-2" @click="changeAppLocale('el')">Ελληνικά</span>
            <span class="text-primary small cursor-pointer" @click="changeAppLocale('en')">English</span>
            <hr class="my-2"/>
        </div>

        <!-- FOOTER LINKS -->
        <div class="text-center mb-4">
            <p class="small text-muted mb-1" v-if="hasProbValidTranslation('branding.name')">{{ $t('branding.name') }}</p>
            <a class="small mr-2" v-if="hasProbValidTranslation('dataPrivacyAndConsent.privacyPolicyUrl')" :href="$t('dataPrivacyAndConsent.privacyPolicyUrl')">{{ $t('Privacy Policy') }}</a>
            <a class="small mr-2" :href="subscribeUrl1">{{ $t('Subscribe') }}</a>
            <a class="small" :href="unsubscribeUrl1">{{ $t('Unsubscribe') }}</a>
        </div>

        <!-- CLOUTLAYER BRAND -->
        <div class="text-center mb-4" v-if="displayCloutlayerBrand">
            <a href="https://www.cloutlayer.com" target="_blank" title="Powered by Cloutlayer">
                <logo :color="'#000000'"></logo>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TenantBoxFooter',
    props: {
        displayI18nLinks: {
            type: Boolean,
            required: false,
            default: true
        },
        displayCloutlayerBrand: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        word () {
            let word = '';
            if (this.$route.path.includes('/email')) {
                word = 'email';
            } else if (this.$route.path.includes('/phone-number')) {
                word = 'phone-number';
            }
            return word;
        },
        subscribeUrl1 () {
            if (this.$t('branding.tenantId') !== 'branding.tenantId') {
                return '/' + this.$t('branding.tenantId') + '/subscribe/' + this.word;
            } else {
                return '#';
            }
        },
        unsubscribeUrl1 () {
            if (this.$t('branding.tenantId') !== 'branding.tenantId') {
                return '/' + this.$t('branding.tenantId') + '/unsubscribe/' + this.word;
            } else {
                return '#';
            }
        }
    }
};
</script>
