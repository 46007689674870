/****************************************************************************************************
 * URL Service
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 2018
 * @lastModifiedAt 9 November 2021
 ****************************************************************************************************/

import {
    CLOUTLAYER_WEBAPP_BASE_URL,
    CLOUTLAYER_WEBAPP_CORE_URL,
    CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL,
    CLOUTLAYER_KERNEL_BASE_URL,
    CLOUTLAYER_KERNEL_API_VERSION
} from '../config/config';

const UrlService = {
    checkAndCleanPath (path = '') {
        // Check path (if null).
        if (path == null) {
            throw new Error('Path cannot be null');
        }
        // Check path (if string).
        if (typeof path !== 'string') {
            throw new Error('Path must be a String');
        }
        // Remove first slash (if exists).
        if (path.startsWith('/')) {
            path = path.slice(1);
        }
        // Remove last slash (if exists).
        if (path.endsWith('/')) {
            path = path.slice(0, path.length - 1);
        }
        return path;
    },

    urlForKernel (path = '') {
        path = UrlService.checkAndCleanPath(path);
        // noinspection UnnecessaryLocalVariableJS
        const url = CLOUTLAYER_KERNEL_BASE_URL + '/' + path;
        return url;
    },
    urlForKernelVersionedApi (path = '') {
        path = UrlService.checkAndCleanPath(path);
        // noinspection UnnecessaryLocalVariableJS
        const url = CLOUTLAYER_KERNEL_BASE_URL + '/api/' + CLOUTLAYER_KERNEL_API_VERSION + '/' + path;
        return url;
    },

    urlForWebAppCorePublic (path = '') {
        path = UrlService.checkAndCleanPath(path);
        // noinspection UnnecessaryLocalVariableJS
        const url = CLOUTLAYER_WEBAPP_CORE_URL + '/' + path;
        return url;
    },
    urlForWebAppBasePublic (path = '') {
        path = UrlService.checkAndCleanPath(path);
        // noinspection UnnecessaryLocalVariableJS
        const url = CLOUTLAYER_WEBAPP_BASE_URL + '/' + path;
        return url;
    },
    urlForWebAppSubscriptionsPublic (path = '') {
        path = UrlService.checkAndCleanPath(path);
        // noinspection UnnecessaryLocalVariableJS
        const url = CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL + '/' + path;
        return url;
    }
};

export default UrlService;
