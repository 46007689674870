import i18n from '../../common/plugins/i18n';
import messages from '../../common/plugins/i18n-messages';
import { PortalInformationService } from '../../common/services/api.service';

/**
 * We have 3 facts:
 * - Application's default langKey is 'en'
 * - Application can set the language from url.
 * - Each tenant account has a default langKey.
 * - Currently, we support only 'el' and 'en'
 */
export default function (mixinName = 'Unknown') {
    return {
        name: 'GenericListingFor' + mixinName + 'Mixin',
        data () {
            return {
                // Data from url //////////
                tenantId: null,
                action: null, // subscribe or unsubscribe
                langKey: 'en', // @see the documentation above.

                // Portal Information Persistence //////////
                fetchingPortalInformation: false,
                portalInformation: null,
                portalInformationError: null,
                i18nPortalContent: {
                    'el': {},
                    'en': {}
                }
            };
        },
        computed: {
            appLocale () { // To make the locale reactive.
                return i18n.locale;
            }
        },
        watch: {
            'appLocale' (value) { // On each change, change the document title.
                this.changeDocumentTitle();
            }
        },
        methods: {
            changeDocumentTitle () {
                // Set the document title.
                if (this.$t('branding.name') !== null && this.$t('branding.name') !== 'branding.name') {
                    document.title = this.$t('branding.name') + ' - ' + this.$t('Email Communication Subscription');
                } else {
                    document.title = this.$t('Email Communication Subscription');
                }

                // @future : Implement favicon change.
                /*
                // Set the favicon.
                if (this.$t('branding.logoFavicon') !== null && this.$t('branding.logoFavicon') !== 'branding.logoFavicon') {
                    document.getElementById('logoFavicon').href = this.$t('branding.logoFavicon');
                } else {
                    // @future : Set the default.
                }
                */
            },
            explainRoute () {
                // Start loader.
                this.fetchingPortalInformation = true;

                // Reset the data.
                this.portalInformation = null;
                this.portalInformationError = null;

                // Get data from url (route).
                let tenantId = this.$route.params.tenantId;
                let action = this.$route.params.action;
                let prefLangKey = this.$route.query.prefLangKey; // prefLangKey stands for 'Preferred Language Key'.

                // NOTICE ABOUT 'prefLangKey'.
                // In a multilingual env we sometimes need to override the tenant's default lang key.
                // The way to do this, it by passing the 'prefLangKey' to URL queries.
                // Then this mixin (only if tenant account has the i18n add-on enabled)
                // will replace the default language key value provided by PortalInformation object.
                // Normally, this mixin would set the defaultLanguageKey that exists in portalInformation object.
                // This happens on application load. Later user has the ability to change language
                // (only if tenant has i18n add-on enabled).

                // Check tenantId
                if (!tenantId) {
                    // Stop loader.
                    this.fetchingPortalInformation = false;

                    // Abort.
                    return Promise.resolve({
                        tenantFound: false
                    });
                }

                // Check action
                if (!action) {
                    // Stop loader.
                    this.fetchingPortalInformation = false;

                    // Abort.
                    return Promise.resolve({
                        tenantFound: false
                    });
                }

                // @help : No need to check action if is equal to 'subscribe' or 'unsubscribe'. We use regex in router.

                // Set tenantId.
                this.tenantId = tenantId;
                this.action = action;

                // Get the portal information.
                return PortalInformationService.getPortalInformation(tenantId).then(({ data }) => {
                    this.portalInformation = data;
                    this.portalInformationError = null;

                    // Set the messages to library so we can access the tenant i18n content.
                    for (let brandingItem of this.portalInformation.brandingList) {
                        if (brandingItem.languageKey) {
                            this.i18nPortalContent[brandingItem.languageKey.toLowerCase()]['branding'] = brandingItem;
                            messages[brandingItem.languageKey.toLowerCase()]['branding'] = brandingItem;
                        }
                    }

                    for (let dataPrivacyAndConsentItem of this.portalInformation.dataPrivacyAndConsentList) {
                        if (dataPrivacyAndConsentItem.languageKey) {
                            this.i18nPortalContent[dataPrivacyAndConsentItem.languageKey.toLowerCase()]['dataPrivacyAndConsent'] = dataPrivacyAndConsentItem;
                            messages[dataPrivacyAndConsentItem.languageKey.toLowerCase()]['dataPrivacyAndConsent'] = dataPrivacyAndConsentItem;
                        }
                    }

                    // Change the document title.
                    this.changeDocumentTitle();

                    // Set the language.
                    let langKey = this.portalInformation.defaultLanguageKey;

                    // If tenant has i18n add-on enabled and prefLangKey exists in URL query params,
                    // set langKey with the value of prefLangKey.
                    // It will be validated right after.
                    // @future : @improvement : If prefLangKey is invalid then
                    // it's better to fall back in tenant's defaultLanguageKey than set 'en'.
                    // But until then, avoid complex implementation with many fall-backs and KISS.
                    if (this.portalInformation.i18nAddOnEnabled === true && prefLangKey !== null && prefLangKey !== undefined) {
                        langKey = prefLangKey;
                    }

                    // Set a safe value if not valid.
                    if (langKey === null || langKey === undefined || typeof langKey !== 'string') {
                        langKey = 'en';
                    } else {
                        langKey = langKey.toLowerCase();
                        if (langKey !== 'el' && langKey !== 'en') {
                            langKey = 'en';
                        }
                    }

                    // Set the app's locale.
                    this.changeAppLocale(langKey);

                    return Promise.resolve({
                        tenantFound: true
                    });
                }).catch((reason) => {
                    this.portalInformation = null;
                    this.portalInformationError = reason;
                    return Promise.resolve({
                        tenantFound: false
                    });
                }).finally(() => {
                    // Stop loader.
                    this.fetchingPortalInformation = false;
                });
            }
        }
    };
};
