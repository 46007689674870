import Vue from 'vue';
import _ from 'lodash';
import queryString from 'query-string';
import UrlService from './url.service';

const defaultConfiguration = {
    // NOTICE: Public API endpoints must have this set to `false`.
    // @future Create two ApiServices. One for the authenticated API endpoints and one for the public API endpoints.
    withCredentials: false,
    crossDomain: true
};

const ApiService = {
    setHeader () {},
    head (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.head(url, config);
    },
    get (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.get(url, config);
    },
    delete (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.delete(url, config);
    },
    post (url, data = null, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.post(url, data, config);
    },
    put (url, data = null, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.put(url, data, config);
    }
};

export default ApiService;

/**
 * Generic BREAD API Consumer for (our) ecosystem's Microservices.
 *
 * Rules:
 * - URL must not contain params
 * - URL must not have appended the trailing slash '/'.
 *
 * @future : Think about:
 * - The default getAll should contain some search mechanisms (simple queries, simple filters, etc).
 * - Simple search (consumer)
 * - Advanced search (consumer)
 * - Analytics or count (consumer)
 * - Anything you think it might be useful
 */
export const GenericBreadService = {
    createOne (url, instance) {
        return ApiService.post(url, instance);
    },
    updateOne (url, instance) {
        return ApiService.put(url, instance);
    },
    getAll (url, page = 0, size = 10, sortField = 'id', sortType = 'desc') {
        if (sortField === null) {
            sortField = 'id';
        }
        if (sortType === null) {
            sortType = 'desc';
        }
        // @future : Make some validations.
        let sort = sortField + ',' + sortType;
        let config = _.cloneDeep(defaultConfiguration);
        config['params'] = {
            page: page,
            size: size,
            sort: sort
        };
        return ApiService.get(url, config);
    },
    getOne (url, id) {
        url = url + '/' + id;
        return ApiService.get(url);
    },
    deleteOne (url, id) {
        url = url + '/' + id;
        return ApiService.delete(url);
    },
    getMany (url, query) {
        return ApiService.post(url, query);
    }
};

export const EmailSubscriptionService = {
    subscribe (data) {
        if (!data) {
            return Promise.reject(new Error('Data object is null'));
        }
        // @future : Add more validations...
        let query = queryString.stringify(data);
        let path = 'subscriptions/email/subscribe?' + query;
        let url = UrlService.urlForKernel(path);
        return ApiService.get(url);
    }
};

export const PhoneNumberSubscriptionService = {
    subscribe (data) {
        if (!data) {
            return Promise.reject(new Error('Data object is null'));
        }
        // @future : Add more validations...
        let query = queryString.stringify(data);
        let path = 'subscriptions/phonenumber/subscribe?' + query;
        let url = UrlService.urlForKernel(path);
        return ApiService.get(url);
    }
};

export const PortalInformationService = {
    getPortalInformation (id) {
        let path = 'public/portals/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};
